html, body {
  height: 100%;
  margin: 0;
}

#root, .main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.footer {
  background-color: black;
  color: white;
  text-align: left;
  padding: 1em;
  width: 100%;
  margin-top: auto;

  .footer__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer__text {
    max-width: 60%;
    font-size: 14px;
  }

  .footer__image {
    max-width: 8%;
    text-align: right;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    padding: 0.5em;
    min-height: 3rem;

    .footer__content {
      flex-direction: column;
    }

    .footer__text {
      max-width: 100%;
      text-align: left;
    }

    .footer__image {
      max-width: 50%;
      text-align: left;
      margin-top: 0.5em;
    }
  }
}
