.instruction-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  max-height: 80vh; // Установите максимальную высоту для модального окна
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 16px;
  overflow-y: auto; // Включить вертикальную прокрутку, если контент превышает высоту

  @media (max-width: 600px) {
    width: 90%;
    max-width: 90%;
    height: 90%;
    max-height: none; // Убираем максимальную высоту для мобильных устройств
    overflow-y: auto; // Включаем вертикальную прокрутку
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.tabs {
  margin-top: 20px;
}

.tabpanel {
  padding: 16px;
}

.review-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.review-list {
  margin-top: 20px;
}

.review-item {
  margin-bottom: 10px;
  padding: 10px;
  background: #f0f0f0;
  border-radius: 4px;
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.review-icon {
  margin-right: 10px;
  color: #3f51b5;
}

.review-username {
  font-weight: bold;
}

.review-rating {
  display: flex;
  margin-top: 5px;

  .star-filled {
    color: #FFD700;
  }

  .star-empty {
    color: #ccc;
  }
}

.image-link {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}
