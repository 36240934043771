.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
  background-color: #ffffff !important; /* Белый цвет */
  border-bottom: 1px solid #dee2e6;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  a {
    text-decoration: none;
    color: #000;
  }

  img {
    max-height: 50px; 
  }

  .navbar-left,
  .navbar-right {
    display: flex;
    align-items: center;

    li {
      list-style: none;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: #000;
        font-weight: bold;
        padding: 10px;
        transition: color 0.3s;

        &:hover {
          color: #007bff;
        }

        &.active {
          text-decoration: none; 
        }
      }
    }
  }

  .navbar-right {
    .user-profile {
      display: flex;
      align-items: center;
      color: #000;

      .profile-icon {
        width: 30px; 
        height: 30px; 
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
        transition: transform 0.3s;

        &:hover {
          transform: scale(1.1);
        }
      }

      span {
        margin-right: 10px;
        font-weight: bold;
      }

      .logout-button {
        background-color: #dc3545;
        color: white;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.3s;

        &:hover {
          background-color: #c82333;
        }
      }
    }
  }

  .hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;

    .bar {
      height: 3px;
      width: 100%;
      background-color: #000; 
      border-radius: 10px;
    }
  }

  .mobile-notification {
    display: none; 
  }

  .desktop-notification {
    display: flex; 
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 10px;
    background-color: #ffffff !important; /* Белый цвет */
    border-bottom: 1px solid #dee2e6;

    img {
      max-height: 40px; 
    }

    .navbar-left,
    .navbar-right {
      display: none;
    }

    .mobile-notification,
    .hamburger {
      display: flex;
      align-items: center;
    }

    .mobile-notification {
      margin-left: 150px; /* Увеличенный отступ слева */
    }

    .hamburger {
      margin-left: 10px; /* Отступ справа от иконки уведомлений */
    }

    .desktop-notification {
      display: none; 
    }
  }

  .mobile-menu {
    position: fixed;
    top: 60px; /* Высота навбара */
    width: 100%;
    background-color: #fff; 
    z-index: 1000;
    border-top: 1px solid #dee2e6;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;

    &.open {
      transform: scaleY(1);
      opacity: 1;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 10px;
        text-align: center;

        a {
          color: #000;
          font-weight: bold;
          padding: 10px;
          display: block;
          transition: background-color 0.3s;
          text-decoration: none; /* Убираем подчеркивание текста */

          &:hover {
            background-color: #e9ecef;
          }

          &.active {
            text-decoration: none; 
          }
        }

        button {
          width: 100%;
          padding: 10px;
          background-color: #dc3545;
          color: white;
          border: none;
          cursor: pointer;
          border-radius: 0;
          transition: background-color 0.3s;

          &:hover {
            background-color: #c82333;
          }
        }
      }

      .auth-buttons {
        display: flex;
        justify-content: center;
        gap: 10px;

        a {
          width: 45%; 
        }
      }
    }
  }
}

body {
  padding-top: 76px; /* Высота навбара */
}
