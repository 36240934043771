@font-face {
  font-family: "Neue Machina";
  src: url("shared/fonts/neuemachina/NeueMachina-Light.otf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Neue Machina" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.centered {
  display: flex;
  margin: 3rem auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
