.markers-container {
    padding: 20px;

    .search-input {
        padding: 10px;
        margin-bottom: 20px;
        width: 100%;
        max-width: 400px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 4px;
        outline: none;
        transition: border-color 0.3s;

        &:focus {
            border-color: #007bff;
        }
    }

    .markers-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;

        th, td {
            border: 1px solid #ddd;
            padding: 10px;
            text-align: left;
        }

        th {
            background-color: #f2f2f2;
            cursor: pointer;
        }

        img {
            max-width: 100px;
            border-radius: 8px;
        }

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        tr:hover {
            background-color: #ddd;
        }

        .delete-button, .go-to-button {
            padding: 12px 24px; /* Увеличиваем отступы для увеличения ширины кнопок */
            background-color: #f44336;
            color: white;
            border: none;
            border-radius: 20px; /* Максимально закругленные края */
            cursor: pointer;
            transition: background-color 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto; /* Центрируем кнопки */
        }

        .go-to-button {
            background-color: #4caf50;
        }

        .delete-button:hover, .go-to-button:hover {
            background-color: #cc0000;
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: .4s;
            border-radius: 34px;

            &.round {
                border-radius: 34px;
            }

            &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                transition: .4s;
                border-radius: 50%;
            }
        }

        input:checked + .slider {
            background-color: #4CAF50;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px #4CAF50;
        }

        input:checked + .slider:before {
            transform: translateX(26px);
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal {
        background: white;
        padding: 20px;
        border-radius: 8px;
        text-align: center;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        p {
            margin-bottom: 20px;
        }

        .modal-buttons {
            display: flex;
            justify-content: center;

            button {
                padding: 8px 16px;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                margin: 0 10px;
                transition: background-color 0.3s;
            }

            .confirm {
                background-color: #f44336;
                color: white;
            }

            .confirm:hover {
                background-color: #cc0000;
            }

            .cancel {
                background-color: #aaa;
                color: white;
            }

            .cancel:hover {
                background-color: #888;
            }
        }
    }
}
