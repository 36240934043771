.moderation-news-container {
  .news-item {
    padding: 20px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 370px; /* Ограничение по ширине */
    width: 100%; /* Обеспечение адаптивности */
    box-sizing: border-box; /* Включение паддингов и бордеров в общую ширину */

    .news-actions {
      display: flex;
      justify-content: flex-start; /* Изменено на выравнивание по левому краю */
      margin-top: 20px;
      
      button {
        padding: 10px 20px; /* Добавление паддингов */
        margin-right: 10px; /* Разделение кнопок */
        border: none; /* Удаление границ */
        border-radius: 4px; /* Добавление скругленных углов */
        cursor: pointer; /* Указатель курсора */
        background-color: #007bff; /* Цвет фона */
        color: #fff; /* Цвет текста */
        transition: background-color 0.3s; /* Плавный переход при наведении */

        &:hover {
          background-color: #0056b3; /* Цвет фона при наведении */
        }

        &:last-of-type {
          background-color: #dc3545; /* Цвет фона для кнопки удаления */
          
          &:hover {
            background-color: #c82333; /* Цвет фона при наведении для кнопки удаления */
          }
        }
      }
    }
  }

  .add-news-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #218838;
    }
  }
}
