/* NavigationBarModeration.scss */
.navigation-bar-moderation {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: #f8f9fa;
    border-right: 1px solid #ddd;
    min-width: 200px; /* Уменьшение минимальной ширины */

    a {
        text-decoration: none;
        color: #007bff;
        padding: 10px;
        border-radius: 4px;
        display: flex;
        align-items: center;

        &.active {
            background-color: #007bff;
            color: #fff;
        }

        &:hover {
            background-color: #e9ecef;
        }

        .icon {
            margin-right: 10px;
        }
    }
}
