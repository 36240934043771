.container {
  width: 100%;

  label {
    margin-bottom: 0.5em;
  }

  input {
    border-radius: 0.625rem;
  }
}
