$primary: #e31c34;
$outlined: transparent;

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 0;

  cursor: pointer;

  color: white;

  &_primary {
    background-color: $primary;
    max-width: 22.5rem;
    max-height: 3.75rem;
    padding: 18px 64px;
    border-radius: 2.5rem;
  }

  &_second {
    background-color: $primary;
    border-radius: 100%;
    padding: 2em;
  }

  &_outlined {
    background-color: $outlined;
  }
}
