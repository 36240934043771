.interactive-map-container {
  position: relative;
  display: flex;
  justify-content: center; /* Центрируем по горизонтали */
  align-items: center; /* Центрируем по вертикали */
  width: 100%;
  height: 15vh; /* Ограничиваем высоту контейнера */
}

.red-circle {
  position: absolute;
  left: calc(50% - -86px); /* Расположить круг под кнопкой с точным отступом */
  top: 50%;
  transform: translateY(-50%);
  width: 100px;
  height: 100px;
  background-color: #E31C34;
  border-radius: 50%;
  z-index: 0;
}

.interactive-map-button {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid black;
  padding: 0.5em 1em;
  border-radius: 0 50px 50px 0;
  position: relative;
  overflow: hidden;
  max-width: 700px; /* Ограничиваем максимальную ширину кнопки */
  margin-left: 0;

  .interactive-map-text {
    position: relative;
    z-index: 1;
    white-space: pre-line; /* Это позволяет разрывать строки внутри текста */
    font-size: 1.2rem;
    margin-right: 1em;
    line-height: 1; /* Устанавливаем уменьшенное расстояние между строками */
    font-weight: bold; /* Жирный текст */
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1.4px solid black; /* Чуть толще границы */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 1;
    transition: background-color 0.1s, border-color 0.1s; /* Быстрая анимация изменения цвета */

    &:active {
      background-color: #E31C34; /* Красный цвет при нажатии */
      border-color: #E31C34;
    }
  }
}
